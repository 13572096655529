<template>

</template>

<script>
export default {
  name: "jihuo",
  mounted() {
    this.open();
    this.$router.push('/login')
  },
  methods: {
    open() {
      this.$alert('', window.vm.$t('message.password.Activation'), {
        confirmButtonText:window.vm.$t('message.OK'),
      });
    },

  }
};
</script>

<style scoped>

</style>